import { getDesignTokens, responsiveFontSizes } from "@ignite-analytics/theme";
import { createTheme, ThemeProvider } from "@mui/material";
import { deDE, enUS, nbNO } from "@mui/material/locale";
import { useMemo } from "react";
import { IntlProvider } from "react-intl";
import { CompatRouter } from "react-router-dom-v5-compat";

import { ApolloProvider } from "@/lib/apollo";
import { LocaleMessages } from "@/lib/i18n";
import { AppProps } from "@/types";

import { AlertProvider } from "./Alerts";
import { UserContextProvider } from "./UserContext";

type Props = {
    children?: React.ReactNode;
    messages: LocaleMessages;
} & AppProps;

function getMuiLocale(locale: string) {
    switch (locale) {
        case "nb-NO":
            return nbNO;
        case "de-DE":
            return deDE;
        default:
            return enUS;
    }
}

function customErrorFunction(err: Error) {
    console.warn(err);
    return;
}

export const AppProvider: React.FC<Props> = ({ children, theme = "ignite-riddle", locale, messages }) => {
    const designTokens = useMemo(() => getDesignTokens(theme, "light"), [theme]);
    const localization = useMemo(() => getMuiLocale(locale), [locale]);

    return (
        <CompatRouter>
            <UserContextProvider>
                <ThemeProvider theme={responsiveFontSizes(createTheme(designTokens, localization, { name: theme }))}>
                    <IntlProvider messages={messages} locale={locale} key={locale} onError={customErrorFunction}>
                        <ApolloProvider>
                            <AlertProvider>{children}</AlertProvider>
                        </ApolloProvider>
                    </IntlProvider>
                </ThemeProvider>
            </UserContextProvider>
        </CompatRouter>
    );
};
