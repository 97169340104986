import React, { useState } from "react";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom-v5-compat";

import { NewSupplier } from "@/components/NewSupplier";
import { OnboardExistingSuppliers } from "@/components/OnboardExistingSuppliers";
import { OnboardingSupplier } from "@/components/OnboardingSupplier";

export const AppRoutes: React.FC = () => {
    const [open, setOpen] = useState(true);

    const basePath = location.pathname.split("/onboarding/")[0];
    const navigate = useNavigate();

    const handleClose = () => {
        setOpen(false);
        navigate(basePath, { state: { refetch: true } });
    };

    return open ? (
        <Routes>
            <Route path={`${basePath}/onboarding`} element={<Navigate to="/onboarding/new" />} />
            <Route
                path={`${basePath}/onboarding/new`}
                element={<NewSupplier open={open} handleClosePage={handleClose} />}
            />
            <Route
                path={`${basePath}/onboarding/:id`}
                element={<OnboardingSupplier open={open} handleClosePage={handleClose} />}
            />
            <Route
                path={`${basePath}/onboarding/existing`}
                element={<OnboardExistingSuppliers open={open} handleClosePage={handleClose} />}
            />
        </Routes>
    ) : null;
};
