import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import {
    LinearProgress,
    SelectChangeEvent,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { DocumentsList_AssessmentsFileFragment, DocumentsList_SupplierFileFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { DocumentActions } from "./DocumentActions";
import { DocumentTypeSelector } from "./DocumentTypeSelector";

export type DocumentListFiles = DocumentsList_SupplierFileFragment | DocumentsList_AssessmentsFileFragment;

type Props = {
    files: DocumentListFiles[];
    isLoading: boolean;
    supplierId: string;
    isEditor: boolean;
    refetch: () => void;
};

const DocumentsList_UpdateAssessmentsFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateAssessmentsFileMetadataMutation($input: UpdateAssessmentsFileMetadataInput!) {
        updateAssessmentsFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

const DocumentsList_UpdateSupplierFileMetadataMutation = graphql(`
    mutation DocumentsList_UpdateSupplierFileMetadataMutation($input: UpdateSupplierFileMetadataInput!) {
        updateSupplierFileMetadata(input: $input) {
            fileMetaJson
        }
    }
`);

graphql(`
    fragment DocumentsList_SupplierFile on SupplierFile {
        name
        metaJson
        size
        createdAt
        ...DocumentsActions_SupplierFile
    }

    fragment DocumentsList_AssessmentsFile on AssessmentsFile {
        name
        metaJson
        size
        createdAt
        referenceId
    }
`);

export const DocumentsList: React.FC<Props> = ({ files, isLoading, supplierId, isEditor, refetch }) => {
    const { alertUser } = useAlert();

    const { formatMessage, formatDate } = useIntl();
    const [updateSupplierFileMetadata] = useMutation(DocumentsList_UpdateSupplierFileMetadataMutation);
    const [updateAssessmentsFileMetadata] = useMutation(DocumentsList_UpdateAssessmentsFileMetadataMutation);

    const TABLE_HEADERS = [
        formatMessage({ defaultMessage: "File name", description: "Name header" }),
        formatMessage({ defaultMessage: "Type", description: "Document type" }),
        formatMessage({ defaultMessage: "Source", description: "Source header" }),
        formatMessage({ defaultMessage: "Date", description: "Date Added" }),
    ];

    const handleSelectChange = (file: DocumentListFiles, event: SelectChangeEvent) => {
        const { value } = event.target;
        if (file && file.metaJson) {
            const errorMessage = formatMessage(
                {
                    defaultMessage: "Failed to change document type for: {file}",
                    description: "File metadata update fail message",
                },
                { file: file.name }
            );
            const updatedFile = {
                ...file,
                metaJson: JSON.stringify({
                    ...JSON.parse(file.metaJson),
                    doctype: value,
                }),
            };
            if (file.__typename === "AssessmentsFile") {
                updateAssessmentsFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            referenceId: file.referenceId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                    onCompleted: () => refetch(),
                });
            } else {
                updateSupplierFileMetadata({
                    variables: {
                        input: {
                            fileName: updatedFile.name,
                            fileMetaJson: updatedFile.metaJson,
                            supplierId,
                        },
                    },
                    onError: () => {
                        alertUser({
                            value: errorMessage,
                            severity: "error",
                        });
                    },
                    onCompleted: () => refetch(),
                });
            }
            track("Onboarding Page: Selected Document Type", { supplierId, documentType: value });
        }
    };

    const convertDocumentOrigin = (metaJson: string) => {
        const { origin } = JSON.parse(metaJson);
        switch (origin) {
            case "supplier-page":
                return formatMessage({
                    defaultMessage: "Upload",
                    description: "Documents uploaded from supplier-page",
                });
            case "assessment":
                return formatMessage({
                    defaultMessage: "Assessments",
                    description: "Documents uploaded from assessment answer",
                });
            default:
                return formatMessage({ defaultMessage: "Unknown", description: "Unknown document upload origin" });
        }
    };

    return (
        <TableContainer>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        {TABLE_HEADERS.map((header) => (
                            <TableCell key={header}>
                                <Typography variant="textSm">{header}</Typography>
                            </TableCell>
                        ))}
                        <TableCell />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {isLoading && (
                        <TableRow>
                            <TableCell height="30rem" colSpan={4} align="center">
                                <LinearProgress />
                            </TableCell>
                        </TableRow>
                    )}
                    {files.length > 0
                        ? files?.map((file) => (
                              <TableRow key={`${file.name}+${file.metaJson}`}>
                                  <TableCell>
                                      <Typography variant="textSm">{file.name}</Typography>
                                  </TableCell>
                                  <TableCell>
                                      {file.metaJson && (
                                          <NoPermissionTooltip hasPermission={isEditor}>
                                              <DocumentTypeSelector
                                                  file={file}
                                                  onChange={handleSelectChange}
                                                  isEditor={isEditor}
                                              />
                                          </NoPermissionTooltip>
                                      )}
                                  </TableCell>
                                  <TableCell>
                                      {file.metaJson && (
                                          <Typography variant="body2">
                                              {convertDocumentOrigin(file.metaJson)}
                                          </Typography>
                                      )}
                                  </TableCell>
                                  <TableCell>
                                      <Typography variant="body2">
                                          {file.createdAt !== null ? formatDate(file.createdAt) : ""}
                                      </Typography>
                                  </TableCell>
                                  <TableCell>
                                      <DocumentActions file={file} supplierId={supplierId} isEditor={isEditor} />
                                  </TableCell>
                              </TableRow>
                          ))
                        : null}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
