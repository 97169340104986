import { DotsVertical } from "@ignite-analytics/icons";
import {
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Menu,
    MenuItem,
    Stack,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { ContactInformationList_ContactFragment } from "@/gql/graphql";

import { ContactInformation } from "./ContactInformation";

graphql(`
    fragment ContactInformationList_Contact on Contact {
        id
        email
        ...ContactInformation_Contact
    }
`);

type ContactInformationListProps = {
    /** Handler for when the user clicks the edit button, receives the contact as an argument */
    onEdit: (contact: ContactInformationList_ContactFragment) => void;
    onDelete: (id: string) => void;
    onAddContact: () => void;
    contacts: ContactInformationList_ContactFragment[];
    isEditor: boolean;
    loading: boolean;
    error: Error | undefined;
};
export const ContactInformationList: React.FC<ContactInformationListProps> = ({
    onEdit,
    onDelete,
    // onAddContact,
    contacts,
    isEditor,
    loading,
    error,
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [contact, setContact] = useState<ContactInformationList_ContactFragment>();
    const [openDeleteModal, setOpenDeleteModal] = useState(false);
    const { formatMessage } = useIntl();

    function handleOpen(event: React.MouseEvent<HTMLElement>, contact: ContactInformationList_ContactFragment) {
        setAnchorEl(event.currentTarget);
        setContact(contact);
    }

    function handleClose() {
        setAnchorEl(null);
    }

    if (loading) {
        return (
            <Stack direction="row" alignItems="center" justifyContent="center">
                <CircularProgress size={20} />
            </Stack>
        );
    }

    if (error)
        return (
            <Typography>
                <FormattedMessage defaultMessage="Something went wrong..." description="Server error" />
            </Typography>
        );

    if (contacts.length === 0) {
        return (
            <Stack direction="row" justifyContent="center" alignItems="center" mt={-1}>
                <Typography variant="textMd" fontWeight={400}>
                    <FormattedMessage defaultMessage="No contacts found" description="No contacts found message" />
                </Typography>
            </Stack>
        );
    }

    return (
        <Stack direction="column" spacing={2}>
            {contacts.map((contact) => (
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="start"
                    spacing={1}
                    key={contact.id}
                    maxWidth="100%"
                >
                    <ContactInformation contact={contact} />
                    {isEditor && (
                        <IconButton
                            onClick={(e) => {
                                handleOpen(e, contact);
                            }}
                        >
                            <DotsVertical />
                        </IconButton>
                    )}
                </Stack>
            ))}

            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => handleClose()}>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        contact && onEdit(contact);
                    }}
                >
                    <FormattedMessage defaultMessage="Edit" description="Edit contact menu" />
                </MenuItem>
                <MenuItem
                    onClick={() => {
                        handleClose();
                        setOpenDeleteModal(true);
                    }}
                >
                    <FormattedMessage defaultMessage="Delete" description="Delete contact menu" />
                </MenuItem>
            </Menu>

            <Dialog
                open={openDeleteModal}
                onClose={() => {
                    setOpenDeleteModal(false);
                    setContact(undefined);
                }}
            >
                <DialogTitle>{formatMessage({ defaultMessage: "Delete contact" })}</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">
                        {formatMessage(
                            {
                                defaultMessage:
                                    "Are you sure you want to delete {email}? This contact may be removed from related assessments.",
                            },
                            { email: contact?.email }
                        )}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Stack direction="row" spacing={1}>
                        <Button onClick={() => setOpenDeleteModal(false)} color="secondary" variant="text">
                            {formatMessage({ defaultMessage: "Cancel" })}
                        </Button>
                        <Button
                            onClick={() => {
                                contact && onDelete(contact.id);
                                setOpenDeleteModal(false);
                            }}
                            color="error"
                        >
                            {formatMessage({ defaultMessage: "Delete" })}
                        </Button>
                    </Stack>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};
