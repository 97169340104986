import { Plus } from "@ignite-analytics/icons";
import { Accordion, AccordionDetails, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { DocumentsEmptyState } from "@/assets/DocumentsEmptyState";
import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { Documents_SupplierFragment } from "@/gql/graphql";

import { EmptyState } from "../EmptyState";
import { AccordionSummary } from "../index";

import { DocumentsList } from "./DocumentList";
import { UploadButton } from "./UploadButton";

interface OnboardingDocumentsProps {
    open: boolean;
    onChange: (_: React.SyntheticEvent, newExpanded: boolean) => void;
    supplier: Documents_SupplierFragment;
    isEditor: boolean;
    refetch: () => void;
}

graphql(`
    fragment Documents_Supplier on Supplier {
        id
        files {
            ...DocumentsList_SupplierFile
        }
        assessmentsFiles {
            ...DocumentsList_AssessmentsFile
        }
    }

    fragment DocumentsActions_SupplierFile on SupplierFile {
        name
        ...DeleteModal_SupplierFile
    }

    fragment DeleteModal_SupplierFile on SupplierFile {
        name
        metaJson
    }
`);

export const OnboardingDocuments: React.FC<OnboardingDocumentsProps> = ({
    open,
    onChange,
    supplier,
    refetch,
    isEditor,
}) => {
    const { formatMessage } = useIntl();
    const [, setIsLoading] = useState(false);
    const documents = [...supplier.files, ...supplier.assessmentsFiles];

    return (
        <Accordion
            sx={{
                borderRadius: 1,
                "&:before": {
                    display: "none",
                },
            }}
            expanded={open}
            onChange={(e) => onChange(e, !open)}
        >
            <AccordionSummary ariaControls="panel2-content" id="panel2-header">
                <Grid container direction="row" justifyContent="space-between" alignItems="center">
                    <Grid item>
                        <Typography variant="textLg" fontWeight={500}>
                            <FormattedMessage defaultMessage="Documents" description="Documents accordion title" />
                        </Typography>
                    </Grid>
                    <Grid item>
                        {open && documents.length > 0 && (
                            <NoPermissionTooltip hasPermission={isEditor}>
                                <UploadButton
                                    setIsLoading={setIsLoading}
                                    supplierId={supplier.id}
                                    isEditor={isEditor}
                                    refetch={refetch}
                                    size="2xsmall"
                                />
                            </NoPermissionTooltip>
                        )}
                    </Grid>
                </Grid>
            </AccordionSummary>
            <AccordionDetails>
                {documents.length == 0 ? (
                    <EmptyState
                        text={formatMessage({
                            defaultMessage:
                                "Use this space to store documents like ISO certifications or signed code of conduct agreements.",
                            description: "Onboarding documents empty state",
                        })}
                        icon={<DocumentsEmptyState />}
                        isEditor={isEditor}
                        button={
                            <UploadButton
                                isEditor={isEditor}
                                supplierId={supplier.id}
                                setIsLoading={setIsLoading}
                                refetch={refetch}
                                buttonStartIcon={<Plus fontSize="inherit" />}
                                buttonText={formatMessage({
                                    defaultMessage: "Upload document",
                                    description: "Upload document button text",
                                })}
                                size="xsmall"
                            />
                        }
                    />
                ) : (
                    <DocumentsList
                        files={documents}
                        isLoading={false}
                        supplierId={supplier.id}
                        isEditor={isEditor}
                        refetch={refetch}
                    />
                )}
            </AccordionDetails>
        </Accordion>
    );
};
