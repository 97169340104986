export const DocumentsEmptyState: React.FC = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="8" width="38" height="38" rx="19" fill="#EFF8FE" />
            <rect
                x="1.76562"
                y="1.76562"
                width="50.4688"
                height="50.4688"
                rx="25.2344"
                stroke="#38BCF7"
                strokeOpacity="0.16"
                strokeWidth="1.78125"
            />
            <rect
                x="5.32812"
                y="5.32812"
                width="43.3438"
                height="43.3438"
                rx="21.0781"
                stroke="#38BCF7"
                strokeOpacity="0.32"
                strokeWidth="1.78125"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.25 18.2917C20.9383 18.2917 19.875 19.355 19.875 20.6667V33.3334C19.875 34.645 20.9383 35.7084 22.25 35.7084H31.75C33.0617 35.7084 34.125 34.645 34.125 33.3334V23.8334C34.125 23.6234 34.0416 23.422 33.8931 23.2736L29.1431 18.5236C28.9947 18.3751 28.7933 18.2917 28.5833 18.2917H22.25ZM32.1458 23.8334L28.5833 20.2709V23.0417C28.5833 23.4789 28.9378 23.8334 29.375 23.8334H32.1458Z"
                fill="#069CDF"
            />
        </svg>
    );
};
