import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { NoteRow_NoteFragment, Notes_DeleteNoteMutationDocument } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { NoteRow, NoteRowProps } from "./NoteRow";

graphql(`
    mutation Notes_DeleteNoteMutation($input: DeleteSupplierNoteInput!) {
        deleteSupplierNote(input: $input) {
            deletedId
        }
    }
`);

interface DeleteNoteProps {
    note: NoteRow_NoteFragment;
    onClose: () => void;
    noteProps: NoteRowProps;
    onDelete: () => void;
}

export const DeleteNoteModal: React.FC<DeleteNoteProps> = ({ note, onClose, noteProps, onDelete }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [deleteNote] = useMutation(Notes_DeleteNoteMutationDocument, {
        variables: { input: { id: note.id } },
        onCompleted: () => {
            onDelete();
            track("Onboarding Page: Deleted note", {
                note: note.note,
            });
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to delete note",
                    description: "Failed to delete note alert message",
                }),
                severity: "error",
            });
        },
    });
    return (
        <Dialog open={true} onClose={onClose}>
            <DialogTitle>
                <FormattedMessage defaultMessage="Delete note" description="Delete note modal title" />
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    <Typography variant="body2">
                        <FormattedMessage
                            defaultMessage="Are you sure you want to delete this note? This will delete it for all users permanently."
                            description="Delete note modal warning"
                        />
                    </Typography>

                    <NoteRow {...noteProps} />
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} variant="text" color="secondary">
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button onClick={() => deleteNote()} variant="contained" color="primary">
                    <FormattedMessage defaultMessage="Delete" description="Delete button" />
                </Button>
            </DialogActions>
        </Dialog>
    );
};
