export const AssessmentsEmptyState: React.FC = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="8" width="38" height="38" rx="19" fill="#EFF8FE" />
            <rect
                x="1.76562"
                y="1.76562"
                width="50.4688"
                height="50.4688"
                rx="25.2344"
                stroke="#38BCF7"
                strokeOpacity="0.16"
                strokeWidth="1.78125"
            />
            <rect
                x="5.32812"
                y="5.32812"
                width="43.3438"
                height="43.3438"
                rx="21.0781"
                stroke="#38BCF7"
                strokeOpacity="0.32"
                strokeWidth="1.78125"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21.4585 19.083C20.1468 19.083 19.0835 20.1463 19.0835 21.458V30.958C19.0835 32.2697 20.1468 33.333 21.4585 33.333H24.0273L26.4742 35.508C26.7742 35.7747 27.2262 35.7747 27.5261 35.508L29.973 33.333H32.5418C33.8535 33.333 34.9168 32.2697 34.9168 30.958V21.458C34.9168 20.1463 33.8535 19.083 32.5418 19.083H21.4585ZM25.6101 24.8825C25.7655 24.2784 26.3148 23.833 26.966 23.833C27.7393 23.833 28.3661 24.4598 28.3661 25.233C28.3661 25.5355 28.2559 25.7525 27.9337 26.0659C27.7754 26.2199 27.5906 26.3732 27.3595 26.5651L27.2891 26.6235C27.0342 26.8353 26.7413 27.0818 26.4443 27.3751C26.1332 27.6823 26.13 28.1836 26.4373 28.4947C26.7445 28.8058 27.2457 28.8089 27.5568 28.5017C27.8019 28.2597 28.0498 28.05 28.3009 27.8413L28.376 27.7791C28.5966 27.5962 28.8316 27.4014 29.0377 27.2008C29.5231 26.7286 29.9494 26.1283 29.9494 25.233C29.9494 23.5854 28.6137 22.2497 26.966 22.2497C25.5748 22.2497 24.4078 23.2012 24.0767 24.4878C23.9677 24.9112 24.2226 25.3428 24.646 25.4518C25.0695 25.5608 25.5011 25.3059 25.6101 24.8825ZM26.9768 30.1663C26.5395 30.1663 26.1851 30.5208 26.1851 30.958C26.1851 31.3952 26.5395 31.7497 26.9768 31.7497H26.9847C27.4219 31.7497 27.7764 31.3952 27.7764 30.958C27.7764 30.5208 27.4219 30.1663 26.9847 30.1663H26.9768Z"
                fill="#069CDF"
            />
        </svg>
    );
};
