import { User } from "@ignite-analytics/icons";
import { Avatar, Stack, Tooltip, Typography } from "@mui/material";
import React from "react";

import { graphql } from "@/gql";
import { ContactInformation_ContactFragment } from "@/gql/graphql";

type Props = {
    contact: ContactInformation_ContactFragment;
};

graphql(`
    fragment ContactInformation_Contact on Contact {
        firstName
        lastName
        email
        phone {
            number
        }
        position
    }
`);

export const ContactInformation: React.FC<Props> = ({ contact }) => {
    let initials = "";

    if (contact.firstName && contact.lastName) {
        initials = `${contact.firstName.charAt(0)}${contact.lastName.charAt(0)}`.toUpperCase();
    }

    return (
        <Stack direction="row" width="170px">
            <Avatar color="none">{initials ? initials : <User />}</Avatar>
            <Stack direction="column" paddingLeft={1.5} flex="1">
                <Typography variant="textSm">
                    {contact.firstName} {contact.lastName}
                </Typography>
                {contact.email && (
                    <Tooltip title={contact.email}>
                        <Typography
                            sx={{
                                maxWidth: "180px", // drop when onboarding dialog widened
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                            }}
                            variant="textSm"
                            fontWeight={400}
                            color="text.text-helper"
                        >
                            {contact.email}
                        </Typography>
                    </Tooltip>
                )}
                {contact.phone.number && (
                    <Typography variant="textSm" fontWeight={400} color="text.text-helper">
                        {contact.phone.number}
                    </Typography>
                )}
                {contact.position && (
                    <Typography variant="textSm" fontWeight={400} color="text.text-helper">
                        {contact.position}
                    </Typography>
                )}
            </Stack>
        </Stack>
    );
};
