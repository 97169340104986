import { Plus } from "@ignite-analytics/icons";
import { Button, Stack, Typography } from "@mui/material";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

interface EmptyStateProps {
    text: string;
    isEditor: boolean;
    icon?: React.ReactNode;
    buttonText?: string;
    onClick?: () => void;
    button?: React.ReactNode;
}

export const EmptyState: React.FC<EmptyStateProps> = ({ text, isEditor, icon, buttonText, button, onClick }) => {
    return (
        <Stack alignItems="center" justifyContent="center" height="100%" spacing={1}>
            {icon}
            <Typography variant="caption" color="text.text-helper">
                {text}
            </Typography>
            {buttonText && onClick && (
                <NoPermissionTooltip hasPermission={isEditor}>
                    <Button
                        startIcon={<Plus fontSize="small" />}
                        onClick={onClick}
                        disabled={!isEditor}
                        color="secondary"
                        size="2xsmall"
                    >
                        {buttonText}
                    </Button>
                </NoPermissionTooltip>
            )}
            {button}
        </Stack>
    );
};
