import { gql, useQuery } from "@apollo/client";
import { formatDateAsUTC, formatTime } from "@ignite-analytics/locale";
import { Chip, Divider, Grid, Stack, Typography } from "@mui/material";
import { useMemo } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { Onboarding_GetUsersDocument, Onboarding_UserFragment, OnboardingFields_SupplierFragment } from "@/gql/graphql";
import { useAlert } from "@/providers";

gql(`
    query Onboarding_GetUsers($input: GetUsersInput!) {
        getUsers(input: $input) {
            result {
                ...Onboarding_User
           }
        }
    }
`);

gql(`
    fragment Onboarding_User on RoleUser {
        id
        firstName
        lastName
    }
`);

gql(`
    fragment OnboardingFields_Supplier on Supplier {
        onboarding {
            status
            approverId
            evaluatedAt
        }
    }
`);

interface Props {
    onboardingFields: OnboardingFields_SupplierFragment;
}

export const OnboardingFields: React.FC<Props> = ({ onboardingFields }) => {
    const { status, approverId, evaluatedAt } = onboardingFields?.onboarding ?? {};
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const { data: userData } = useQuery(Onboarding_GetUsersDocument, {
        variables: {
            input: {
                terms: "",
                userIds: [approverId ?? ""],
            },
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to fetch supplier data",
                    description: "Failed to fetch supplier alert message",
                }),
                severity: "error",
            });
        },
    });

    const userDetails = useMemo(() => {
        if (!userData?.getUsers?.result) {
            return [];
        }
        return userData.getUsers.result as Onboarding_UserFragment[];
    }, [userData]);

    return (
        <>
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Onboarding status" description="Onboarding status" />
                        </Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" paddingRight={3}>
                        <Typography variant="textSm">
                            {status == "IN_ONBOARDING" ? (
                                <Chip
                                    label={<FormattedMessage defaultMessage="In onboarding" />}
                                    color="warning"
                                    size="small"
                                />
                            ) : status == "APPROVED" ? (
                                <Chip
                                    label={<FormattedMessage defaultMessage="Approved" />}
                                    color="success"
                                    size="small"
                                />
                            ) : (
                                <Chip
                                    label={<FormattedMessage defaultMessage="Rejected" />}
                                    color="error"
                                    size="small"
                                />
                            )}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
            <Divider orientation="horizontal" flexItem />
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Approved by" description="Approved by" />
                        </Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" paddingRight={3}>
                        <Typography variant="textSm">
                            {approverId && userDetails[0] && (
                                <Chip label={`${userDetails[0].lastName}, ${userDetails[0].firstName}`} size="small" />
                            )}
                            {approverId && !userDetails[0] && <FormattedMessage defaultMessage="User not found" />}
                        </Typography>
                    </Grid>
                </Grid>
            </Stack>
            <Divider orientation="horizontal" flexItem />
            <Stack justifyContent="center" sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">
                            <FormattedMessage defaultMessage="Evaluated at" description="Evaluated at" />
                        </Typography>
                    </Grid>
                    <Grid xs={8} textAlign="end" paddingRight={3}>
                        {approverId && evaluatedAt && (
                            <Typography fontSize="textSm">
                                {formatDateAsUTC(evaluatedAt)} {formatTime(evaluatedAt)}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Stack>
        </>
    );
};
