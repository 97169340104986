import { useQuery } from "@apollo/client";
import { Accordion, AccordionDetails, Divider, Skeleton, Stack, Typography } from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";

import { AssessmentsEmptyState } from "@/assets/AssessmentsEmptyState";
import { graphql } from "@/gql";
import { AssessmentsPage_CampaignFragment, AssessmentsPage_QuestionnaireFragment, CampaignType } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { AccordionSummary } from "..";
import { EmptyState } from "../EmptyState";

import AssessSupplierButton from "./AssessSupplierButton";
import { AnswerRow } from "./Components/AnswerRow";
import { getAssessmentInstances } from "./utils";

interface OnboardingAssessmentsProps {
    isEditor: boolean;
    supplierId: string;
    open: boolean;
    onChange: (_: React.SyntheticEvent, newExpanded: boolean) => void;
}

graphql(`
    fragment AssessmentsPage_Assessment on Assessment {
        id
        submittedAt
        answers {
            id
        }
        campaign {
            ...AssessmentsPage_Campaign
        }
        score
    }
    fragment AssessmentsPage_Campaign on Campaign {
        id
        name
        questionnaire {
            ...AssessmentsPage_Questionnaire
        }
        type
        dueDate
    }
    fragment AssessmentsPage_Questionnaire on Questionnaire {
        id
        name
    }
`);

const getAssessments = graphql(`
    query AssessmentsPage_GetAssessments($input: GetAssessmentsInput!) {
        getAssessments(input: $input) {
            assessments {
                id
                ...AssessmentsPage_Assessment
            }
        }
    }
`);

export const OnboardingAssessments: React.FC<OnboardingAssessmentsProps> = ({
    supplierId,
    isEditor,
    onChange,
    open,
}) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const { data, loading } = useQuery(getAssessments, {
        skip: !supplierId,
        variables: {
            input: { supplierId: supplierId },
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to get assessment data",
                    description: "Get assessment data fail message",
                }),
                severity: "error",
            });
        },
    });

    const allAssessments = data?.getAssessments?.assessments ?? [];

    // Instances can either be an external campaign, and internal campaign, or an internal questionnaire (all campaigns for that questionnaire)
    const assessmentInstances = getAssessmentInstances(data?.getAssessments?.assessments ?? []);
    const getProps = (instance: AssessmentsPage_CampaignFragment | AssessmentsPage_QuestionnaireFragment) => {
        if ((instance as AssessmentsPage_CampaignFragment).dueDate) {
            const campaign = instance as AssessmentsPage_CampaignFragment;
            if (campaign.type === CampaignType.External) {
                // External campaign
                return {
                    title: campaign.name,
                    dueDate: campaign.dueDate,
                    score: allAssessments.find((a) => a.campaign?.id === campaign.id)?.score,
                    props: {
                        assessmentId:
                            data?.getAssessments.assessments.find((a) => a.campaign?.id === campaign?.id)?.id ?? "",
                        questionnaireId: campaign.questionnaire?.id,
                    },
                };
            } else {
                // Internal campaign
                return {
                    title: campaign.name,
                    dueDate: campaign.dueDate,
                    score: allAssessments
                        .filter((a) => a.campaign?.id === campaign.id)
                        .reduce((acc, a) => acc + a.score, 0),
                    props: {
                        questionnaireId: campaign.questionnaire.id,
                        campaignId: campaign.id,
                        supplierId: supplierId,
                    },
                };
            }
        } else {
            // All campaigns for this internal questionnaire
            const questionnaire = instance as AssessmentsPage_QuestionnaireFragment;
            return {
                title: questionnaire.name,
                score: allAssessments
                    .filter((a) => a.campaign?.questionnaire?.id === questionnaire.id)
                    .reduce((acc, a) => acc + a.score, 0),
                props: {
                    questionnaireId: questionnaire.id,
                    campaignId: "",
                    supplierId: supplierId,
                },
            };
        }
    };

    const internal = assessmentInstances.filter(
        (instance) => (instance as AssessmentsPage_CampaignFragment).type !== CampaignType.External
    );
    const external = assessmentInstances.filter(
        (instance) => (instance as AssessmentsPage_CampaignFragment).type == CampaignType.External
    );

    return (
        <Accordion
            sx={{
                borderRadius: 1,
                "&:before": {
                    display: "none",
                },
            }}
            expanded={open}
            onChange={(e) => onChange(e, !open)}
        >
            <AccordionSummary ariaControls="panel1-content" id="panel1-header">
                <Stack width="100%" direction="row" justifyContent="space-between">
                    <Typography variant="textLg" fontWeight={500}>
                        <FormattedMessage defaultMessage="Assessments" description="Assessments accordion title" />
                    </Typography>
                    {data?.getAssessments.assessments.length !== 0 && (
                        <AssessSupplierButton supplierId={supplierId} isEditor={true} />
                    )}
                </Stack>
            </AccordionSummary>
            <AccordionDetails>
                <Divider sx={{ marginTop: -1 }} />
                {loading ? (
                    <Stack alignItems="center" justifyContent="center" height="100%" spacing={1} margin={2}>
                        <Skeleton variant="rectangular" height={200} />
                    </Stack>
                ) : data?.getAssessments.assessments.length === 0 ? (
                    <Stack alignItems="center" justifyContent="center" height="100%" spacing={1} margin={2}>
                        <EmptyState
                            text={formatMessage({
                                defaultMessage: "Add the supplier to a campaign to gather necessary information",
                                description: "Onboarding assessments module empty state",
                            })}
                            icon={<AssessmentsEmptyState />}
                            isEditor={isEditor}
                            button={<AssessSupplierButton supplierId={supplierId} isEditor={isEditor} />}
                        />
                    </Stack>
                ) : (
                    <Stack spacing={2} paddingTop={2}>
                        {internal?.length > 0 && (
                            <>
                                <Typography variant="textXl" fontWeight={500} paddingTop={1}>
                                    <FormattedMessage defaultMessage="Internal" />
                                </Typography>
                                {internal.map((instance) => {
                                    const { title, dueDate, score, props } = getProps(instance);
                                    return (
                                        <AnswerRow
                                            key={instance.id}
                                            name={title}
                                            dueDate={dueDate}
                                            score={score}
                                            props={{ ...props, supplierId: props.supplierId || "" }}
                                        />
                                    );
                                })}
                            </>
                        )}
                        {external?.length > 0 && (
                            <>
                                <Typography variant="textXl" fontWeight={500}>
                                    <FormattedMessage defaultMessage="External" />
                                </Typography>
                                {external.map((instance) => {
                                    const { title, dueDate, score, props } = getProps(instance);
                                    return (
                                        <AnswerRow
                                            key={instance.id}
                                            name={title}
                                            dueDate={dueDate}
                                            score={score}
                                            props={{ ...props, supplierId: props.supplierId || "" }}
                                        />
                                    );
                                })}
                            </>
                        )}
                    </Stack>
                )}
            </AccordionDetails>
        </Accordion>
    );
};
