import { Dialog } from "@mui/material";
import React from "react";

interface LayoutProps {
    children: React.ReactNode;
    open: boolean;
    handleClose: () => void;
    maxWidth: "sm" | "md" | "lg";
}

export const Layout: React.FC<LayoutProps> = ({ children, open, handleClose, maxWidth }) => {
    return (
        <Dialog
            maxWidth={maxWidth}
            open={open}
            disablePortal
            onClose={() => handleClose()}
            fullWidth
            PaperProps={{
                sx: {
                    maxHeight: "80vh",
                },
            }}
        >
            {children}
        </Dialog>
    );
};
