import { ApolloError, useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import {
    alpha,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    Divider,
    TextField as MuiTextField,
    Stack,
    TextFieldProps,
    Typography,
} from "@mui/material";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Field, FieldProps, Form, Formik } from "formik";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";

import { ContactForm as IContactForm, validationSchema } from "./validation";

const UPSERT_CONTACT_MUTATION = graphql(`
    mutation UpsertContactsMutation($input: UpsertContactsInput!) {
        upsertContacts(input: $input) {
            result {
                id
                companyId
            }
        }
    }
`);

/**
 * Wrapper around MuiTextField to use with Formik
 */
const TextField: React.FC<TextFieldProps & { name: keyof IContactForm }> = ({ name, ...props }) => {
    return (
        <Field name={name}>
            {({ field, meta }: FieldProps<IContactForm>) => (
                <MuiTextField
                    {...field}
                    size="small"
                    error={Boolean(meta.touched && meta.error)}
                    helperText={meta.touched && meta.error}
                    {...props}
                />
            )}
        </Field>
    );
};

const emptyContact: IContactForm = {
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    position: "",
};

type Props = {
    supplierId: string;
    /* Already existing values for a contact to pre-fill the form */
    defaultValues?: IContactForm;
    /* ID of the contact being edited, `undefined` if adding a new supplier */
    id?: string;
    /* Callback when a contact has been successfully added/edited */
    onComplete: () => void;
    /* Calback when the user cancels the form */
    onCancel: () => void;
    /* Callback when the submission fails */
    onError: (error: ApolloError) => void;
};

/**
 * A form to manage contacts with. Clears the `getContacts`-cache on success.
 */
export const ContactForm: React.FC<Props> = ({
    supplierId,
    defaultValues = emptyContact,
    id,
    onComplete,
    onCancel,
    onError,
}) => {
    const [upsertContacts] = useMutation(UPSERT_CONTACT_MUTATION, {
        onCompleted() {
            onComplete();
            track("Onboarding page: Upserted contact");
        },
        update: (cache, { data }) => {
            const addedContact = data?.upsertContacts.result[0];
            if (!addedContact) return;
            cache.modify({
                fields: {
                    getContactsBySupplierId: (exisitingSupplierContacts) => {
                        const supplierContacts = exisitingSupplierContacts.result.contacts;
                        return {
                            ...exisitingSupplierContacts,
                            result: [
                                supplierContacts
                                    ? [...supplierContacts, addedContact]
                                    : {
                                          supplierId: addedContact.companyId,
                                          contacts: addedContact,
                                          __typename: "SupplierContacts",
                                      },
                            ],
                        };
                    },
                },
            });
        },
        onError: (error) => {
            onError(error);
        },
    });
    const { formatMessage } = useIntl();
    const isEditing = typeof id !== "undefined";

    function handleSubmit({ phoneNumber, position, firstName, lastName, email }: IContactForm) {
        upsertContacts({
            variables: {
                input: {
                    contacts: [
                        {
                            id: id ?? "",
                            companyId: supplierId,
                            position,
                            firstName,
                            lastName,
                            email,
                            phone: {
                                number: phoneNumber,
                            },
                        },
                    ],
                },
            },
        });
    }

    return (
        <Formik
            onSubmit={(values) => handleSubmit(values)}
            validationSchema={validationSchema}
            initialValues={defaultValues}
        >
            <Form style={{ height: "100%" }}>
                <Card
                    sx={{
                        height: "100%",
                        justifyContent: "space-between",
                        display: "flex",
                        flexDirection: "column",
                        maxHeight: "45vh",
                        borderColor: (theme) => theme.palette.secondary.main,
                        boxShadow: (theme) => `0px 0px 0px 4px ${alpha(theme.palette.secondary.main, 0.2)}`,
                    }}
                >
                    <CardHeader
                        title={
                            <>
                                <Typography variant="textLg">
                                    {isEditing
                                        ? formatMessage({
                                              defaultMessage: "Edit contact",
                                              description: "Edit contact title",
                                          })
                                        : formatMessage({
                                              defaultMessage: "Add contact",
                                              description: "Add contact title",
                                          })}
                                </Typography>
                                <Divider />
                            </>
                        }
                    />
                    <CardContent sx={{ overflow: "auto" }}>
                        <Stack spacing={2}>
                            <TextField
                                name="email"
                                required
                                label={formatMessage({
                                    defaultMessage: "Email",
                                    description: "Email label",
                                })}
                            />
                            <TextField
                                name="firstName"
                                label={formatMessage({
                                    defaultMessage: "First name",
                                    description: "First name label",
                                })}
                            />
                            <TextField
                                name="lastName"
                                label={formatMessage({
                                    defaultMessage: "Last name",
                                    description: "Last name label",
                                })}
                            />

                            <TextField
                                name="phoneNumber"
                                label={formatMessage({
                                    defaultMessage: "Phone number",
                                    description: "Phone number label",
                                })}
                            />
                            <TextField
                                name="position"
                                label={formatMessage({
                                    defaultMessage: "Position",
                                    description: "Position label",
                                })}
                            />
                        </Stack>
                    </CardContent>
                    <CardActions sx={{ mt: "auto" }}>
                        <Stack direction="row" justifyContent="flex-end" spacing={1} width="100%">
                            <Button onClick={() => onCancel()} variant="outlined" color="secondary">
                                <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                            </Button>
                            <Button type="submit">
                                {isEditing ? (
                                    <FormattedMessage defaultMessage="Save" description="Save button" />
                                ) : (
                                    <FormattedMessage defaultMessage="Add" description="Add button" />
                                )}
                            </Button>
                        </Stack>
                    </CardActions>
                </Card>
            </Form>
        </Formik>
    );
};
