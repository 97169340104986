import { ChevronDown } from "@ignite-analytics/icons";
import {
    Autocomplete,
    AutocompleteChangeReason,
    AutocompleteRenderInputParams,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from "@mui/material";
import { DebouncedFunc } from "lodash";
import { useEffect, useRef, useState } from "react";

import { getLabel } from "./helpers";
import { NACEOption, NACEOptions } from "./NACEOptions";

const sx = {
    "& .MuiOutlinedInput-root": {
        borderRadius: "0",
        paddingY: "0!important",
        margin: "0!important",
        fontSize: "12px",
        height: "40px",
    },
    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
    },
    width: "100%",
};

interface NaceFieldProps {
    initialValue: string;
    columnId: string;
    onChange: DebouncedFunc<(columnId: string, value: string | null) => void>;
}

export const NaceField: React.FC<NaceFieldProps> = ({ initialValue, onChange, columnId }) => {
    const [option, setOption] = useState<NACEOption | null>(
        NACEOptions.find((c) => c.nace === initialValue?.slice(0, 5)) ?? null
    );
    const [open, setOpen] = useState<boolean>(false);

    const inputRef = useRef<HTMLInputElement>(null);
    const autoCompleteRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<HTMLDivElement>(null);

    const handleSetOption = (option: NACEOption | null) => {
        const newValue = option ? option.nace : null;
        setOption(option);
        onChange(columnId, newValue);
        setOpen(false);
    };

    // handle click outside autocomplete and popper
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                autoCompleteRef.current &&
                !autoCompleteRef.current.contains(event.target as Node) &&
                !popperRef.current?.contains(event.target as Node)
            ) {
                setOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [autoCompleteRef, popperRef]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFocus = () => {
        setOpen(true);
        inputRef.current?.focus();
    };

    const handleClickPopupIndicator = (e: React.SyntheticEvent<Element, Event>) => {
        e.stopPropagation();
        e.preventDefault();
        if (!open) {
            setOpen(true);
            inputRef.current?.focus();
            return;
        }
        setOpen(false);
    };

    const renderOption = (props: React.HTMLAttributes<HTMLLIElement>, option: NACEOption) => {
        return (
            <MenuItem {...props} key={`select-choice-${option.nace}`} sx={{ whiteSpace: "pre-line" }}>
                <Typography variant="body2" fontSize="medium" color="textPrimary" width="100%">
                    {getLabel(option)}
                </Typography>
            </MenuItem>
        );
    };

    const handleChange = (
        _: React.SyntheticEvent<Element, Event>,
        value: NACEOption | null,
        reason: AutocompleteChangeReason
    ) => {
        if (reason === "selectOption") {
            handleSetOption(value);
        } else if (reason === "clear") {
            inputRef.current?.focus();
            handleSetOption(null);
        }
    };

    const renderInput = (params: AutocompleteRenderInputParams) => {
        return (
            <TextField
                {...params}
                size="medium"
                sx={{ fontSize: "initial" }}
                inputRef={inputRef}
                onChange={() => setOpen(true)}
            />
        );
    };

    const isOptionEqualToValue = (option: NACEOption, value: NACEOption) => {
        return option.nace === value.nace;
    };

    return (
        <Tooltip title={!open && getLabel(option)} placement="top">
            <Autocomplete
                id={`nace-${columnId}`}
                sx={sx}
                open={open}
                tabIndex={0}
                autoHighlight
                value={option}
                options={NACEOptions}
                ref={autoCompleteRef}
                onOpen={handleOpen}
                onClick={handleOpen}
                onClose={handleClose}
                onFocus={handleFocus}
                onChange={handleChange}
                renderInput={renderInput}
                renderOption={renderOption}
                isOptionEqualToValue={isOptionEqualToValue}
                getOptionLabel={(option) => `${getLabel(option)}`}
                popupIcon={<ChevronDown fontSize="small" />}
                componentsProps={{
                    popper: {
                        ref: popperRef,
                    },
                    paper: {
                        elevation: 5,
                    },
                    popupIndicator: {
                        onClick: handleClickPopupIndicator,
                    },
                }}
                ListboxProps={{ style: { maxHeight: "250px" } }}
            />
        </Tooltip>
    );
};
