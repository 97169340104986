import { NACEOption } from "./NACEOptions";

export function getLabel(option: NACEOption | null) {
    if (!option) return "";
    const locale = navigator.language;

    if (locale === "nb") {
        return `${option.nace}: ${option.name_no}`;
    }
    return `${option.nace}: ${option.name_en}`;
}
