import { MenuItem, Select } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

interface Props {
    onChange(fieldId: string, fieldData: string): void;
    fieldId: string;
    initialValue: string;
    options: string[] | undefined;
}

export const SelectField: React.FC<Props> = ({ onChange, fieldId, initialValue, options }) => {
    const [value, setValue] = useState<string>(initialValue);
    return (
        <Select
            size="small"
            value={value}
            onChange={(fieldVal) => {
                onChange(fieldId, fieldVal.target.value);
                setValue(fieldVal.target.value);
            }}
            fullWidth
        >
            <MenuItem value="">
                <FormattedMessage defaultMessage="Clear value" />
            </MenuItem>
            {options?.map((s: string) => {
                return (
                    <MenuItem value={s} key={s}>
                        {s}
                    </MenuItem>
                );
            })}
        </Select>
    );
};
