import * as yup from "yup";

export const validationSchema = yup.object({
    firstName: yup.string().label("First name").nullable().optional(),
    lastName: yup.string().label("Last name").nullable().optional(),
    email: yup.string().email().label("Email").required(),
    /* phone() validation fails if the field is blank, so we circumvent this with conditional validation */
    phoneNumber: yup.string().nullable().optional().label("Phone number"),
    position: yup.string().label("Position").nullable().optional(),
});

export type ContactForm = yup.InferType<typeof validationSchema>;
