import { ApolloClient, ApolloLink, from, HttpLink, InMemoryCache, NormalizedCacheObject } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";

let apolloClient: ApolloClient<NormalizedCacheObject> | null = null;

export const getGraphqlClient = (errorHandler?: ApolloLink) => {
    const uri = import.meta.env.REACT_APP_GRAPHQL_ROUTER_URL;
    if (apolloClient) {
        return apolloClient;
    }
    const authenticationLink = setContext(async () => {
        const tenant = localStorage.getItem("tenant");

        const headers = {
            ...(tenant ? { "x-tenant-id": tenant } : {}),
        };
        return {
            headers,
            fetchOptions: {
                credentials: "include",
            },
        };
    });

    const retryLink = new RetryLink({
        delay: {
            initial: 100,
            max: Infinity,
            jitter: true,
        },
        attempts: {
            max: 3,
            retryIf: (error) => !!error && typeof error === "object" && error.message === "Failed to fetch",
        },
    });
    const errorHandlerList = errorHandler ? [errorHandler] : [];
    const httpLink = new HttpLink({ uri, credentials: "include" });
    const time = new Date().getTime();
    apolloClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: from([...errorHandlerList, authenticationLink, retryLink, httpLink]),
        name: `onboarding-apollo-client-${time}`,
    });

    return apolloClient;
};
