import { Stack, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";

import { AssessmentsEmptyState } from "@/assets/AssessmentsEmptyState";

export const NotResponded: React.FC = () => {
    return (
        <Stack
            alignItems="center"
            width="100%"
            height="100%"
            mb={4}
            sx={{ backgroundColor: "white", borderRadius: "8px" }}
        >
            <Stack alignItems="center" padding={2}>
                <AssessmentsEmptyState />
                <Typography variant="h5" sx={{ mt: 2, mb: 2 }}>
                    <FormattedMessage defaultMessage="No response" description="No response" />
                </Typography>
                <Typography variant="body1">
                    <FormattedMessage
                        defaultMessage="You can send out a reminder from the campaign page."
                        description="You can send out a reminder text"
                    />
                </Typography>
            </Stack>
        </Stack>
    );
};
