import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { NoteRow_NoteFragment, Notes_CreateNoteMutationDocument } from "@/gql/graphql";
import { useAlert } from "@/providers";

interface CreateNoteProps {
    isEditor: boolean;
    supplierId: string;
    onAdd: (newNote: NoteRow_NoteFragment) => void;
}

graphql(`
    mutation Notes_CreateNoteMutation($input: CreateSupplierNoteInput!) {
        createSupplierNote(input: $input) {
            note {
                ...NoteRow_Note
            }
        }
    }
`);

export const CreateNote: React.FC<CreateNoteProps> = ({ supplierId, onAdd, isEditor }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const [newNote, setNewNote] = useState("");

    const [createNote] = useMutation(Notes_CreateNoteMutationDocument, {
        variables: { input: { supplierId: supplierId, note: newNote } },
        onCompleted: (res) => {
            onAdd(res.createSupplierNote.note);
            setNewNote("");
            track("Onboarding Page: Created note", {
                note: newNote,
            });
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to create note",
                    description: "Failed to create note alert message",
                }),
                severity: "error",
            });
        },
    });

    return (
        <Stack direction="row" width="100%">
            <TextField
                multiline
                fullWidth
                disabled={!isEditor}
                maxRows={4}
                placeholder={formatMessage({
                    defaultMessage: "Type a new note here",
                    description: "Notes placeholder",
                })}
                variant="outlined"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                sx={{ borderRadius: 2 }}
                inputProps={{ maxLength: 1000 }}
            />
            <Button
                color="primary"
                size="small"
                disabled={!newNote || !isEditor}
                onClick={() => createNote()}
                sx={{ marginRight: 1, marginTop: 2 }}
            >
                <FormattedMessage defaultMessage="Post" />
            </Button>
        </Stack>
    );
};
