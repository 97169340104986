import { Divider, Stack, TextField, Typography, Unstable_Grid2 as Grid } from "@mui/material";
import { DebouncedFunc } from "lodash";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { KnownFields_SupplierFragment } from "@/gql/graphql";

interface Props {
    onUpdate: DebouncedFunc<(fieldId: string, fieldData: string) => void>;
    edit: boolean;
    supplier: KnownFields_SupplierFragment;
    knownFieldNames: Record<string, string>;
}

graphql(`
    fragment KnownFields_Supplier on Supplier {
        name
        orgNumber
        country
    }
`);

interface knownFields {
    key: string;
    value: string;
    keyName: string;
}

export const KnownFields: React.FC<Props> = ({ onUpdate, edit, supplier, knownFieldNames }) => {
    const { formatMessage } = useIntl();
    const fields: knownFields[] = [
        {
            key: "name",
            value: supplier?.name ?? "",
            keyName: knownFieldNames["name"] ?? formatMessage({ defaultMessage: "Name" }),
        },
        {
            key: "reg_nr",
            value: supplier?.orgNumber ?? "",
            keyName: knownFieldNames["reg_nr"] ?? formatMessage({ defaultMessage: "Registration number" }),
        },
        {
            key: "country",
            value: supplier?.country ?? "",
            keyName: knownFieldNames["country"] ?? formatMessage({ defaultMessage: "Country" }),
        },
    ];
    return (
        <>
            {fields.map((field) => (
                <>
                    <Stack justifyContent="center" key={field.key} sx={{ height: "52px", width: "100%" }}>
                        <Grid container alignItems="center" width="100%">
                            <Grid xs={4} alignContent="center" paddingLeft={3}>
                                <Typography variant="textSm">{field.keyName}</Typography>
                            </Grid>

                            <Grid xs={8} textAlign="end" paddingRight={3}>
                                {edit ? (
                                    <TextField
                                        size="small"
                                        variant="outlined"
                                        type="text"
                                        defaultValue={field.value}
                                        onChange={(fieldVal) => onUpdate(field.key, fieldVal.target.value)}
                                        fullWidth
                                    />
                                ) : (
                                    <Typography variant="textSm">{field.value}</Typography>
                                )}
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider orientation="horizontal" flexItem />
                </>
            ))}
        </>
    );
};
