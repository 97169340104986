import { useMutation } from "@apollo/client";
import { useFeatureToggle } from "@ignite-analytics/feature-toggle";
import { Button } from "@mui/material";
import React from "react";
import { useIntl } from "react-intl";

import { graphql } from "@/gql";
import { getContentType, getFileExtension } from "@/lib/files";
import { track } from "@/lib/track";
import { useAlert } from "@/providers";

const MAX_FILE_SIZE = 20 * 1024 * 1024;
const ACCEPTED_FILE_EXTENSIONS = ".pdf,.csv,.txt,.rtf,.xls,.xlsx,.json,.ppt,.pptx,.xls,.xlsx,.doc,.docx,.xml";

type Props = {
    isEditor: boolean;
    supplierId: string;
    size?: "medium" | "small" | "xsmall" | "2xsmall";
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
    refetch: () => void;
    color?: "primary" | "secondary";
    buttonStartIcon?: React.ReactNode;
    buttonText?: string;
};

const Documents_CreateUploadURL = graphql(`
    mutation Documents_CreateUploadURL($input: CreateSupplierFileUploadUrlInput!) {
        createSupplierFileUploadUrl(input: $input) {
            url
        }
    }
`);

export const UploadButton: React.FC<Props> = ({
    isEditor,
    supplierId,
    buttonStartIcon,
    setIsLoading,
    color = "secondary",
    refetch,
    buttonText = "Upload",
    size = "small",
}) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();
    const featureToggle = useFeatureToggle("content-headers-in-files");

    const [uploadFile] = useMutation(Documents_CreateUploadURL);
    const handleUploadFiles = async (selectedFiles: FileList | null) => {
        if (!selectedFiles) return;
        setIsLoading(true);

        const blockedFiles: string[] = [];
        const selectedFilesInfo = Array.from(selectedFiles).reduce(
            (acc: { name: string; file: File }[], selectedFile) => {
                const fileName = selectedFile.name;
                if (selectedFile.size > MAX_FILE_SIZE) {
                    blockedFiles.push(fileName);
                } else {
                    acc.push({ name: fileName, file: selectedFile });
                }
                return acc;
            },
            []
        );

        if (blockedFiles.length > 0) {
            const blockedFilesList = blockedFiles.join(", ");
            alertUser({
                value: formatMessage(
                    {
                        defaultMessage: "{files} could not be uploaded because of file size (larger than 20MB).",
                        description: "Error uploading file",
                    },
                    { files: blockedFilesList }
                ),
                severity: "error",
            });
            track("Supplier Onboarding: Attempted to Upload Too Large Document", { supplierId });
        }

        if (selectedFilesInfo.length < 1) {
            setIsLoading(false);
            return;
        }
        const uploadPromises = selectedFilesInfo.map(async (selectedFileInfo) => {
            // create signed upload url
            const data = await uploadFile({
                variables: {
                    input: {
                        supplierId: supplierId,
                        fileName: selectedFileInfo.name,
                        fileMetaJson: JSON.stringify({
                            origin: "supplier-page",
                        }),
                    },
                },
                onError: () => {
                    alertUser({
                        value: formatMessage(
                            {
                                defaultMessage: "Error uploading file {file}",
                                description: "Error uploading file",
                            },
                            { file: selectedFileInfo.name }
                        ),
                        severity: "error",
                    });
                },
            });

            const url = data.data?.createSupplierFileUploadUrl.url;
            if (!url) return false;

            const fileExtension = getFileExtension(selectedFileInfo.name);
            if (!fileExtension) return false;

            let contentType = "application/octet-stream";
            if (featureToggle) {
                contentType = getContentType(fileExtension);
            }

            const headers: { [key: string]: string } = {
                "x-goog-meta-origin": "supplier-page",
                "x-goog-content-length-range": "0,20971520",
                "Content-Type": contentType,
            };

            if (featureToggle) {
                const re = new RegExp(`[&/\\#,+()$~%'":*?<>{}]`, "g");
                const sanitizedFileName = selectedFileInfo.name.replace(re, "_");
                headers["x-goog-meta-av_scanned"] = "FALSE";
                headers["x-goog-meta-originalfilename"] = sanitizedFileName;
                headers["Content-Disposition"] = `attachment; filename="${sanitizedFileName}"`;
            }

            // upload the file to the signed url
            return fetch(url, {
                method: "PUT",
                headers: headers,
                body: selectedFileInfo.file,
            }).catch(() => {
                alertUser({
                    value: formatMessage(
                        {
                            defaultMessage: "Error uploading file {file}",
                            description: "Error uploading file",
                        },
                        { file: selectedFileInfo.name }
                    ),
                    severity: "error",
                });
            });
        });
        await Promise.all(uploadPromises).finally(() => {
            refetch();
            setIsLoading(false);
            track("Onboarding Page: Uploaded Document", { supplierId });
        });
    };

    return (
        <Button
            component="label"
            onClick={(e) => {
                e.stopPropagation();
            }}
            startIcon={buttonStartIcon}
            disabled={!isEditor}
            color={color}
            size={size}
        >
            {buttonText}
            <input
                type="file"
                multiple
                accept={ACCEPTED_FILE_EXTENSIONS}
                hidden
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleUploadFiles(e.target.files);
                }}
                onClick={(e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
                    // Have to reset the value of the input to allow uploading the same file twice
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    e.target.value = null;
                }}
            />
        </Button>
    );
};
