import { styled } from "@mui/material/styles";

export const Chip = styled("div")((props) => ({
    border: "none",
    backgroundColor: props.color ?? "rgba(0, 0, 0, 0.38)",
    font: "inherit",
    borderRadius: "4px",
    fontSize: "inherit",
    fontWeight: "inherit",
    padding: "0 12px",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    textTransform: "capitalize",
}));

export const classificationChipColors = ["#ffd4e0", "#ffe0cc", "#ffeab6", "#c1f5f0", "#d1e2ff"];

export type RiskValues = "low" | "medium" | "high";

export const getRiskChipColor = (value: RiskValues) => {
    switch (value) {
        case "low":
            return "#D8F9E1";
        case "medium":
            return "#FEF2C7";
        case "high":
            return "#FFE5E3";
    }
};
