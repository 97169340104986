import { useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, CardContent, CardHeader, Divider, Snackbar, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import { ContactInformationList_ContactFragment, Contacts_DeleteContactsMutation } from "@/gql/graphql";

import { ContactForm, IContactForm } from "./ContactForm";
import { ContactInformationList } from "./ContactInformationList";

interface OnboardingContactsProps {
    supplierId: string;
    isEditor: boolean;
}

type EditContactState = {
    /* ID of the contact being edited, `undefined` if adding a contact */
    id?: string;
    /* Already existing values for a given contact to pre-fill the form */
    defaultValues?: IContactForm;
};

const GET_CONTACTS_QUERY = graphql(`
    query Contacts_GetContacts($input: GetContactsBySupplierIdInput!) {
        getContactsBySupplierId(input: $input) {
            result {
                supplierId
                contacts {
                    ...ContactInformationList_Contact
                }
            }
        }
    }
`);

const DELETE_CONTACTS_MUTATION = graphql(`
    mutation Contacts_DeleteContacts($input: DeleteContactsInput!) {
        deleteContacts(input: $input) {
            ids
            count
        }
    }
`);

export const OnboardingContacts: React.FC<OnboardingContactsProps> = ({ supplierId, isEditor }) => {
    const [editContact, setEditContact] = useState<EditContactState | undefined>(undefined);
    const [error, setError] = useState(false);

    const result = useQuery(GET_CONTACTS_QUERY, {
        variables: {
            input: { supplierId: supplierId ?? "" },
        },
        skip: !supplierId,
    });

    const [deleteContacts] = useMutation(DELETE_CONTACTS_MUTATION, {
        optimisticResponse(vars): Contacts_DeleteContactsMutation {
            return {
                deleteContacts: {
                    ids: vars.input.ids,
                    count: vars.input.ids.length,
                    __typename: "DeleteContactsResponse",
                },
                __typename: "Mutation",
            };
        },
        update(cache, { data }) {
            if (data?.deleteContacts.ids.length) {
                data.deleteContacts.ids.forEach((id) => {
                    cache.evict({ id: `Contact:${id}` });
                });
                cache.gc();
            }
        },
    });

    const onEdit = (contact: ContactInformationList_ContactFragment) => {
        setEditContact({
            id: contact.id,
            defaultValues: {
                firstName: contact.firstName,
                lastName: contact.lastName,
                email: contact.email ?? "",
                phoneNumber: contact.phone.number,
                position: contact.position,
            },
        });
    };

    if (typeof editContact !== "undefined") {
        return (
            <>
                <Snackbar
                    autoHideDuration={10000}
                    open={error}
                    anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
                >
                    <Alert severity="error" onClose={() => setError(false)}>
                        <FormattedMessage
                            defaultMessage="Something went wrong, please try again later."
                            description="Generic error message"
                        />
                    </Alert>
                </Snackbar>
                <ContactForm
                    supplierId={supplierId}
                    {...editContact}
                    onError={() => setError(true)}
                    onComplete={() => setEditContact(undefined)}
                    onCancel={() => setEditContact(undefined)}
                />
            </>
        );
    }

    const contacts = result.data?.getContactsBySupplierId.result.contacts ?? [];

    return (
        <Card
            sx={{
                overflow: "auto",
                minWidth: "300px",
                height: "100%",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                title={
                    <Stack spacing={1}>
                        <Typography variant="textLg" fontWeight={500}>
                            <Stack justifyContent="space-between" direction="row" alignItems="center">
                                <FormattedMessage
                                    defaultMessage="Contact person"
                                    description="Contact person section title"
                                />

                                <Button
                                    size="2xsmall"
                                    color="secondary"
                                    disabled={!isEditor}
                                    onClick={() => setEditContact({})}
                                >
                                    <FormattedMessage defaultMessage="Add new" description="Add contact button" />
                                </Button>
                            </Stack>
                        </Typography>
                        <Divider />
                    </Stack>
                }
            />
            <CardContent sx={{ overflow: "auto", height: "100%" }}>
                <ContactInformationList
                    isEditor={isEditor}
                    onEdit={onEdit}
                    contacts={contacts}
                    error={result.error}
                    onAddContact={() => setEditContact({})}
                    loading={result.loading}
                    onDelete={(id) => {
                        deleteContacts({
                            variables: {
                                input: {
                                    ids: [id],
                                },
                            },
                        });
                    }}
                />
            </CardContent>
        </Card>
    );
};
