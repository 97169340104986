import { createBrowserHistory } from "history";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Router } from "react-router-dom";

import { setupProxyAuth } from "@/setupProxyAuth";
import { AppProps } from "@/types";

import App from "./App";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import { loadMessages } from "./lib/i18n";
import { AppProvider } from "./providers/app";

window.renderOnboarding = (containerId, props) => {
    renderApp(containerId, props);
};

let root: ReturnType<typeof createRoot> | null = null;

window.unmountOnboarding = () => {
    root && root.unmount();
};

// running locally
if (!document.getElementById("Onboarding-container")) {
    const isProxyToProductionBackend = import.meta.env.REACT_APP_PROXY_WEB_TO_PRODUCTION === "true";
    if (isProxyToProductionBackend) {
        setupProxyAuth().then(() => {
            renderAppLocally("root", {
                locale: "en-US",
                theme: "ignite-riddle",
            });
        });
    } else {
        renderAppLocally("root", {
            locale: "en-US",
            theme: "ignite-riddle",
        });
    }
}

function renderApp(rootNode = "root", props: AppProps = { locale: "en-US", theme: "ignite-riddle" }) {
    const messages = loadMessages(props.locale);
    const history = props.history ? props.history : createBrowserHistory();
    const container = document.getElementById(rootNode);

    if (!container) {
        return;
    }
    root = createRoot(container);

    root.render(
        <React.StrictMode>
            <Router history={history}>
                <AppProvider {...props} messages={messages}>
                    <ErrorBoundary>
                        <App />
                    </ErrorBoundary>
                </AppProvider>
            </Router>
        </React.StrictMode>
    );
}

// Running locally
function renderAppLocally(rootNode = "root", props: AppProps = { locale: "en-US", theme: "ignite-riddle" }) {
    const messages = loadMessages(props.locale);
    const container = document.getElementById(rootNode);
    if (!container) return;
    root = createRoot(container);
    root.render(
        <React.StrictMode>
            <BrowserRouter>
                <AppProvider {...props} messages={messages}>
                    <App />
                </AppProvider>
            </BrowserRouter>
        </React.StrictMode>
    );
}
