import { useMutation } from "@apollo/client";
import { track } from "@ignite-analytics/track";
import { Button, Stack, TextField } from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { NoteRow_NoteFragment, Notes_UpdateNoteMutationDocument } from "@/gql/graphql";
import { useAlert } from "@/providers";

graphql(`
    mutation Notes_UpdateNoteMutation($input: UpdateSupplierNoteInput!) {
        updateSupplierNote(input: $input) {
            updatedNote {
                ...NoteRow_Note
            }
        }
    }
`);

interface EditNoteProps {
    oldNote: NoteRow_NoteFragment;
    onClose: () => void;
    onUpdate: (updatedNote: NoteRow_NoteFragment, oldNote: NoteRow_NoteFragment) => void;
}

export const EditNote: React.FC<EditNoteProps> = ({ oldNote, onClose, onUpdate }) => {
    const { formatMessage } = useIntl();
    const { alertUser } = useAlert();

    const [newNote, setNewNote] = useState(oldNote.note);
    const [updateNote] = useMutation(Notes_UpdateNoteMutationDocument, {
        variables: { input: { id: oldNote.id, note: newNote } },
        onCompleted: (res) => {
            onUpdate(res.updateSupplierNote.updatedNote, oldNote);
            track("Onboarding Page: Updated note", {
                oldNote: oldNote.note,
                newNote: newNote,
            });
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "Failed to update note",
                    description: "Failed to update note alert message",
                }),
                severity: "error",
            });
        },
    });
    return (
        <Stack direction="row" marginLeft={-2} marginRight={-6}>
            <TextField
                multiline
                fullWidth
                maxRows={4}
                placeholder="Type your note here"
                variant="outlined"
                value={newNote}
                onChange={(e) => setNewNote(e.target.value)}
                sx={{ borderRadius: 2 }}
                inputProps={{ maxLength: 1000 }}
            />
            <Stack direction="row" spacing={1} paddingTop={2}>
                <Button variant="text" color="secondary" size="small" onClick={() => onClose()}>
                    <FormattedMessage defaultMessage="Cancel" />
                </Button>
                <Button variant="text" color="primary" size="small" disabled={!newNote} onClick={() => updateNote()}>
                    <FormattedMessage defaultMessage="Save" />
                </Button>
            </Stack>
        </Stack>
    );
};
