import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { Onboarding_CreateSupplierMutation, OnboardingStatus } from "@/gql/graphql";
import { useAlert } from "@/providers";

import { Layout } from "../Layout";

interface NewSupplierProps {
    open: boolean;
    handleClosePage: () => void;
}

const CreateSupplier_OnboardingMutation = graphql(`
    mutation Onboarding_CreateSupplier($input: CreateSupplierInput!) {
        createSupplier(input: $input) {
            supplier {
                id
                onboarding {
                    status
                }
            }
        }
    }
`);

export const NewSupplier: React.FC<NewSupplierProps> = ({ open, handleClosePage }) => {
    const [onboardingStatus, setOnboardingStatus] = useState<OnboardingStatus>(OnboardingStatus.InOnboarding);
    const [name, setName] = useState("");

    const { alertUser } = useAlert();

    const navigate = useNavigate();
    const { formatMessage } = useIntl();

    const path = location.pathname.split(/onboarding/);
    const basePath = path[0];

    const [createSupplier] = useMutation(CreateSupplier_OnboardingMutation, {
        onCompleted: (data: Onboarding_CreateSupplierMutation) => {
            if (data?.createSupplier.supplier.onboarding?.status === OnboardingStatus.InOnboarding) {
                navigate(`${basePath}onboarding-list/onboarding/${data.createSupplier.supplier.id}`);
            } else {
                // or do we want to navigate to the supplier page?
                navigate(`${basePath}supplier-page/${data.createSupplier.supplier.id}/overview`);
            }
        },
        onError: () => {
            alertUser({
                value: formatMessage({
                    defaultMessage: "An error occurred while creating the supplier",
                    description: "Alert message when creating a supplier fails",
                }),
                title: formatMessage({
                    defaultMessage: "Error",
                    description: "Error",
                }),
                severity: "error",
            });
        },
    });

    return (
        <Layout open={open} handleClose={handleClosePage} maxWidth="sm">
            <DialogTitle mb={2}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <Typography variant="h6">
                        <FormattedMessage defaultMessage="Add supplier" description="Add supplier header" />
                    </Typography>
                    <IconButton onClick={() => handleClosePage()}>
                        <X />
                    </IconButton>
                </Stack>
                <Typography variant="textSm" color="text.text-helper">
                    <FormattedMessage
                        defaultMessage="To get started onboarding your supplier, please provide the following information"
                        description="New supplier onboarding header text"
                    />
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Stack my={2}>
                        <FormControl>
                            <TextField
                                id="supplierName"
                                label={
                                    <Typography variant="textMd">
                                        <FormattedMessage
                                            defaultMessage="Supplier name"
                                            description="Onboarding field"
                                        />
                                    </Typography>
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setName(event.target.value)}
                                size="small"
                                helperText={
                                    <Typography variant="textSm" color="text.text-helper">
                                        <FormattedMessage defaultMessage="Required" description="Required" />
                                    </Typography>
                                }
                            ></TextField>
                        </FormControl>
                    </Stack>
                    <Divider />
                    <Stack spacing={2} my={2}>
                        <Typography variant="h6">
                            <FormattedMessage
                                defaultMessage="Supplier already onboarded?"
                                description="Supplier already onboarded checkbox"
                            />
                        </Typography>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                        setOnboardingStatus(
                                            event.target.checked
                                                ? OnboardingStatus.Approved
                                                : OnboardingStatus.InOnboarding
                                        );
                                    }}
                                    name="supplierOnboardedAlready"
                                />
                            }
                            label={
                                <Stack>
                                    <Typography variant="textSm" color="text.text-secondary" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Check this box if the supplier is already onboarded" />
                                    </Typography>
                                    <Typography variant="textSm" color="text.text-helper">
                                        <FormattedMessage defaultMessage="This will skip the onboarding process and directly add the supplier to the table" />
                                    </Typography>
                                </Stack>
                            }
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={() => handleClosePage()}>
                    <FormattedMessage defaultMessage="Cancel" description="Cancel button" />
                </Button>
                <Button
                    color="primary"
                    onClick={() => {
                        createSupplier({
                            variables: {
                                input: {
                                    name,
                                    customFields: [],
                                    onboardingStatus: onboardingStatus,
                                },
                            },
                        });
                        track("Onboarding page: Created supplier", {
                            onboardingStatus,
                            name,
                        });
                    }}
                    disabled={!name}
                >
                    <FormattedMessage defaultMessage="Next" description="Next button" />
                </Button>
            </DialogActions>
        </Layout>
    );
};
