import { useState } from "react";

type Relation = {
    object: "general";
    relation: "read" | "write";
};

export async function checkPermissions(relationTuples: Relation[], namespace: string): Promise<boolean[]> {
    const res = await fetch(`${process.env.REACT_APP_AUTH_SIDECAR_API_URL}/permissions/check/${namespace}`, {
        method: "POST",
        body: JSON.stringify(relationTuples),
        credentials: "include",
    });
    if (!res.ok) {
        throw new Error(`Failed to check permissions: ${res.statusText}`);
    }

    const data: boolean[] = await res.json();
    return Array.isArray(data) ? data : Array(relationTuples.length).fill(false);
}

export function usePermission(relation: Relation) {
    const [hasPermission, setHasPermission] = useState<boolean>(false);
    checkPermissions([relation], "suppliers")
        .then((res) => {
            setHasPermission(res[0]);
        })
        .catch(() => {
            setHasPermission(false);
        });
    return hasPermission;
}
