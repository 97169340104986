import { Divider, Grid, Stack, Typography } from "@mui/material";
import { DebouncedFunc } from "lodash";
import React from "react";

import { graphql } from "@/gql";
import { CustomFields_SupplierCustomFieldFragment, SupplierCustomFieldType } from "@/gql/graphql";

import { ClassificationCustomField } from "..";
import { formatReadCustomField } from "../formatters";

import { EditCustomField } from "./EditCustomField";
import { isNonEditableRiskColumn } from "./utils";

interface Props {
    onChange: DebouncedFunc<(fieldId: string, fieldData: string) => void>;
    edit: boolean;
    customField: CustomFields_SupplierCustomFieldFragment | ClassificationCustomField;
    selectOptions: Map<string, string[]> | undefined;
}

graphql(`
    fragment CustomFields_SupplierCustomField on SupplierCustomField {
        name
        fieldId
        dataJson
        fieldType
    }
`);

export const CustomFields: React.FC<Props> = ({ onChange, edit, customField, selectOptions }) => {
    return (
        <Stack>
            <Stack justifyContent="center" key={customField.fieldId} sx={{ height: "52px", width: "100%" }}>
                <Grid container alignItems="center" width="100%">
                    <Grid xs={4} alignContent="center" paddingLeft={3}>
                        <Typography variant="textSm">{customField.name}</Typography>
                    </Grid>

                    <Grid xs={8} textAlign="end" alignItems="end" paddingRight={3}>
                        {edit && canEditDataColumn(customField) ? (
                            <EditCustomField
                                customField={customField}
                                onChange={onChange}
                                selectOptions={selectOptions}
                            />
                        ) : (
                            formatReadCustomField(customField)
                        )}
                    </Grid>
                </Grid>
            </Stack>
            <Divider />
        </Stack>
    );
};

// Filtering out columns we dont want to edit in the supplier info card
function canEditDataColumn(customField: CustomFields_SupplierCustomFieldFragment) {
    if (
        customField.fieldType === SupplierCustomFieldType.Classification ||
        customField.fieldType === SupplierCustomFieldType.Aggregation ||
        isNonEditableRiskColumn(customField.fieldType)
    ) {
        return false;
    }
    return true;
}
