import { useMutation } from "@apollo/client";
import { Trash } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { graphql } from "@/gql";
import {
    Checklist_TaskFragment,
    Onboarding_GetSupplierDocument,
    TaskRow_CreateTaskDocument,
    TaskRow_DeleteTaskDocument,
    TaskRow_UpdateTaskDocument,
    TaskStatus,
} from "@/gql/graphql";

graphql(`
    mutation TaskRow_CreateTask($input: CreateTaskInput!) {
        createTask(input: $input) {
            task {
                ...Checklist_Task
            }
        }
    }

    mutation TaskRow_UpdateTask($input: UpdateTaskInput!) {
        updateTask(input: $input) {
            task {
                ...Checklist_Task
            }
        }
    }

    mutation TaskRow_DeleteTask($input: DeleteTaskInput!) {
        deleteTask(input: $input) {
            deleted
        }
    }
`);

interface TaskRowProps {
    task: Checklist_TaskFragment;
    setTask: (task: Checklist_TaskFragment) => void;
    removeTask: () => void;
    isEditor: boolean;
}

export const TaskRow: React.FC<TaskRowProps> = ({ task, setTask, removeTask, isEditor }) => {
    const [editing, setEditing] = useState(task.id === "new");
    const [deleteTask, setDelete] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [title, setTitle] = useState(task.title);

    const [createTask] = useMutation(TaskRow_CreateTaskDocument, {
        onCompleted: (res) => {
            setTask(res.createTask.task);
            setEditing(false);
            track("Onboarding Page: Created task", {
                title: res.createTask.task.title,
            });
        },
        update: (cache, { data }) => {
            const newTask = data?.createTask.task;
            if (!newTask) return;
            cache.modify({
                fields: {
                    getSupplier: () => {
                        const oldSupplier = cache.readQuery({
                            query: Onboarding_GetSupplierDocument,
                            variables: { input: { id: task.supplierID } },
                        })?.getSupplier.supplier;
                        return {
                            ...oldSupplier,
                            tasks: [...(oldSupplier?.tasks ?? []), newTask],
                        };
                    },
                },
            });
        },
    });

    const [updateTask] = useMutation(TaskRow_UpdateTaskDocument, {
        onCompleted: (res) => {
            setTask(res.updateTask.task);
            setEditing(false);
            track("Onboarding Page: Updated task", {
                title: res.updateTask.task.title,
            });
        },
        update: (cache, { data }) => {
            const newTask = data?.updateTask.task;
            if (!newTask) return;
            cache.modify({
                fields: {
                    getSupplier: () => {
                        const oldSupplier = cache.readQuery({
                            query: Onboarding_GetSupplierDocument,
                            variables: { input: { id: task.supplierID } },
                        })?.getSupplier.supplier;
                        const tasks = oldSupplier?.tasks?.filter((t) => t.id !== newTask.id) ?? [];
                        return {
                            ...oldSupplier,
                            tasks: [...tasks, newTask],
                        };
                    },
                },
            });
        },
    });

    const handleUpsertTask = () => {
        if (title === "" || title === task.title) {
            setEditing(false);
            return;
        }
        if (task.id === "new") {
            createTask({
                variables: {
                    input: {
                        title: title,
                        status: TaskStatus.Pending,
                        supplierID: task.supplierID,
                    },
                },
            });
        } else {
            updateTask({
                variables: {
                    input: {
                        id: task.id,
                        title: title,
                        status: task.status,
                    },
                },
            });
        }
    };

    const toggleStatus = () => {
        updateTask({
            variables: {
                input: {
                    id: task.id,
                    title: task.title,
                    status: task.status === TaskStatus.Done ? TaskStatus.Pending : TaskStatus.Done,
                },
            },
        });
    };

    const [deleteTaskMutation] = useMutation(TaskRow_DeleteTaskDocument, {
        variables: {
            input: {
                id: task.id,
            },
        },
        onCompleted: () => {
            removeTask();
            track("Onboarding Page: Deleted task", {
                title: task.title,
            });
        },
        update: (cache, { data }) => {
            if (!data?.deleteTask.deleted) return;
            cache.modify({
                fields: {
                    getSupplier: () => {
                        const oldSupplier = cache.readQuery({
                            query: Onboarding_GetSupplierDocument,
                            variables: { input: { id: task.supplierID } },
                        })?.getSupplier.supplier;
                        const tasks = oldSupplier?.tasks?.filter((t) => t.id !== task.id) ?? [];
                        return {
                            ...oldSupplier,
                            tasks: [...tasks],
                        };
                    },
                },
            });
        },
    });

    return (
        <>
            <Stack
                direction="row"
                spacing={1}
                alignItems="center"
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                sx={{ position: "relative" }}
            >
                <>
                    <Checkbox
                        checked={task.status === "DONE"}
                        disabled={task.id === "new" || editing}
                        onChange={() => {
                            toggleStatus();
                        }}
                    />
                    {editing ? (
                        <TextField
                            // eslint-disable-next-line jsx-a11y/no-autofocus
                            autoFocus
                            size="small"
                            type="text"
                            variant="standard"
                            value={title}
                            onKeyDown={(e) => {
                                e.stopPropagation();
                                if (e.key === "Enter" || e.key === "Escape") {
                                    handleUpsertTask();
                                }
                            }}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            onBlur={() => {
                                handleUpsertTask();
                            }}
                            sx={{ flexGrow: 1 }}
                        />
                    ) : (
                        <Typography
                            onClick={() => {
                                setEditing(true);
                            }}
                            variant="body2"
                            sx={{
                                color:
                                    task.status === "DONE" || title === ""
                                        ? (theme) => theme.palette.text.disabled
                                        : "inherit",
                                textDecoration: task.status === "DONE" ? "line-through" : "none",
                                flexGrow: 1,
                            }}
                        >
                            {task.title != "" ? (
                                task.title
                            ) : (
                                <FormattedMessage defaultMessage="Click to start typing..." />
                            )}
                        </Typography>
                    )}
                </>
                {isHovered && isEditor && (
                    <IconButton
                        size="2xsmall"
                        onClick={() => {
                            if (task.id === "new") {
                                removeTask();
                                return;
                            }
                            setDelete(true);
                        }}
                        sx={{ position: "absolute", top: "50%", right: 0, transform: "translateY(-50%)" }}
                    >
                        <Trash />
                    </IconButton>
                )}
            </Stack>
            {deleteTask && (
                <Dialog open={deleteTask} onClose={() => setDelete(false)}>
                    <DialogTitle>
                        <FormattedMessage defaultMessage="Delete task" />
                    </DialogTitle>
                    <DialogContent>
                        <Typography>
                            <FormattedMessage defaultMessage="Are you sure you want to delete this task?" />
                        </Typography>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setDelete(false);
                            }}
                            size="small"
                            color="secondary"
                            variant="text"
                            style={{ marginRight: "auto" }}
                        >
                            <FormattedMessage defaultMessage="Cancel" />
                        </Button>
                        <Button
                            onClick={() => {
                                deleteTaskMutation();
                            }}
                            size="small"
                            style={{ marginLeft: "auto" }}
                        >
                            <FormattedMessage defaultMessage="Delete" />
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </>
    );
};
