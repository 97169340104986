import { TextField } from "@mui/material";
import { DebouncedFunc } from "lodash";
import React from "react";

import { CustomFields_SupplierCustomFieldFragment, SupplierCustomFieldType } from "@/gql/graphql";

import { formatWriteCustomField } from "../../formatters";

import { NaceField } from "./NaceField";
import { SelectField } from "./SelectField";

interface Props {
    onChange: DebouncedFunc<(fieldId: string, fieldData: string) => void>;
    customField: CustomFields_SupplierCustomFieldFragment;
    selectOptions: Map<string, string[]> | undefined;
}

export const EditCustomField: React.FC<Props> = ({ customField, onChange, selectOptions }) => {
    switch (customField.fieldType) {
        case SupplierCustomFieldType.Select: {
            return (
                <SelectField
                    onChange={onChange}
                    fieldId={customField.fieldId}
                    initialValue={formatWriteCustomField(customField.dataJson, customField.fieldType)}
                    options={selectOptions?.get(customField.fieldId)}
                />
            );
        }
        case SupplierCustomFieldType.Nace: {
            return (
                <NaceField
                    initialValue={formatWriteCustomField(customField.dataJson, customField.fieldType)}
                    columnId={customField.fieldId}
                    onChange={onChange}
                />
            );
        }
        default: {
            return (
                <TextField
                    size="small"
                    variant="outlined"
                    type={customField.fieldType === SupplierCustomFieldType.Spend ? "number" : customField.fieldType}
                    defaultValue={formatWriteCustomField(customField.dataJson, customField.fieldType)}
                    onChange={(fieldVal) => onChange(customField.fieldId, fieldVal.target.value)}
                    fullWidth
                />
            );
        }
    }
};
