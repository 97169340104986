import { useMutation, useQuery } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Autocomplete,
    Button,
    Checkbox,
    debounce,
    DialogActions,
    DialogContent,
    DialogTitle,
    IconButton,
    Skeleton,
    Stack,
    TextField,
    Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { graphql } from "@/gql";
import { OnboardExistingSuppliers_SupplierFragment } from "@/gql/graphql";
import { usePermission } from "@/lib/permissions";

import { Layout } from "../Layout";
import { NoPermissionTooltip } from "../NoPermissionTooltip";

import { FilterInput } from "./utils";

graphql(`
    fragment OnboardExistingSuppliers_Supplier on Supplier {
        id
        name
        country
        orgNumber
        onboarding {
            status
        }
    }
`);

const GetSuppliers_OnboardExistingSuppliersQuery = graphql(`
    query OnboardExistingSuppliers_GetSupplier($input: GetSuppliersInput!) {
        getSuppliers(input: $input) {
            suppliers {
                ...OnboardExistingSuppliers_Supplier
            }
        }
    }
`);

const SetSuppliersToInOnboarding_OnboardExistingSuppliersMutation = graphql(`
    mutation OnboardExistingSuppliers_SetSuppliersToInOnboarding($input: SetSuppliersToOnboardingInput!) {
        setSuppliersToOnboarding(input: $input) {
            ok
        }
    }
`);

interface OnboardExistingSuppliersProps {
    open: boolean;
    handleClosePage: () => void;
}

const onboardingFilter: FilterInput[] = [
    {
        type: "exclude",
        column_id: "onboarding.status",
        include_exclude_values: ["in_onboarding"],
        min_range: null,
        max_range: null,
        min_date: null,
        max_date: null,
        include_blanks: true,
    },
];

export const OnboardExistingSuppliers: React.FC<OnboardExistingSuppliersProps> = ({ open, handleClosePage }) => {
    const { formatMessage } = useIntl();
    const [supplierOptions, setSupplierOptions] = useState<OnboardExistingSuppliers_SupplierFragment[]>([]);
    const [selectedSuppliers, setSelectedSuppliers] = useState<OnboardExistingSuppliers_SupplierFragment[]>([]);
    const isEditor = usePermission({ object: "general", relation: "write" });

    const { data, loading, error, refetch } = useQuery(GetSuppliers_OnboardExistingSuppliersQuery, {
        variables: {
            input: {
                pageIndex: 0,
                pageRange: 100,
                filterJson: JSON.stringify(onboardingFilter),
            },
        },
    });

    const [setSuppliersToOnboarding] = useMutation(SetSuppliersToInOnboarding_OnboardExistingSuppliersMutation);

    useMemo(() => {
        if (!data?.getSuppliers?.suppliers) return;
        const selectedNotInOptions = selectedSuppliers.filter((selected) => {
            return !data.getSuppliers.suppliers.some((supplier) => supplier.id === selected.id);
        });
        setSupplierOptions([...selectedNotInOptions, ...data.getSuppliers.suppliers]);
    }, [data, selectedSuppliers]);

    const handleSearch = useMemo(
        () =>
            debounce(function handleSearch(searchTerm: string) {
                track("Supplier Onboarding: Search exsiting suppliers");
                refetch({
                    input: {
                        nameSearch: searchTerm,
                        pageIndex: 0,
                        pageRange: 100,
                        filterJson: JSON.stringify(onboardingFilter),
                    },
                });
            }, 500),
        [refetch]
    );

    const handleUpdateOnboardingStatus = () => {
        track("Supplier Onboarding: Update onboarding status");
        const supplierIDs = selectedSuppliers.map((supplier) => supplier.id);
        setSuppliersToOnboarding({
            variables: {
                input: {
                    supplierIDs,
                },
            },
        }).then(() => {
            handleClosePage();
        });
    };

    if (loading) {
        return <Skeleton variant="rectangular" height={400} />;
    }

    if (error || !supplierOptions) {
        return (
            <Typography>
                <FormattedMessage defaultMessage="Error loading supplier" description="Error loading supplier" />
            </Typography>
        );
    }

    return (
        <Layout open={open} handleClose={handleClosePage} maxWidth="sm">
            <DialogTitle mb={2}>
                <Stack spacing={1}>
                    <IconButton onClick={handleClosePage} sx={{ position: "absolute", top: 8, right: 8 }}>
                        <X />
                    </IconButton>
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="Start onboarding of existing supplier"
                            description="Onboard existing suppliers header"
                        />
                    </Typography>
                    <Typography variant="textSm">
                        <FormattedMessage
                            defaultMessage=" This will set their status back to 'In onboarding', and the supplier will have to go through the approval process"
                            description="Onboard existing suppliers sub-header"
                        />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent>
                <Autocomplete
                    multiple
                    id="select-existing-suppliers-for-onboarding"
                    value={selectedSuppliers}
                    options={supplierOptions}
                    disableCloseOnSelect
                    disableClearable
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    getOptionLabel={(supplier: OnboardExistingSuppliers_SupplierFragment) => supplier.name}
                    getOptionKey={(supplier: OnboardExistingSuppliers_SupplierFragment) => supplier.id}
                    renderOption={(props, supplier, { selected }) => (
                        <li {...props} key={supplier.id} data-hide-check-icon>
                            <Checkbox style={{ marginRight: 8 }} checked={selected} />
                            <Stack direction="row" alignItems="center" spacing={1}>
                                <Typography variant="body1">{supplier.name}</Typography>
                                <Typography variant="caption">{`(${supplier.orgNumber} - ${supplier.country})`}</Typography>
                            </Stack>
                        </li>
                    )}
                    style={{ width: "100%" }}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                label={
                                    <Typography variant="textXs">
                                        <FormattedMessage
                                            defaultMessage="Select Suppliers"
                                            description="Select existing suppliers for onboarding label"
                                        />
                                    </Typography>
                                }
                                placeholder={formatMessage({
                                    defaultMessage: "Search Supplier",
                                    description: "Search existing suppliers for onboarding placeholder",
                                })}
                            />
                        );
                    }}
                    onChange={(_, value) => setSelectedSuppliers(value)}
                    onInputChange={(_, value) => {
                        // if we want do disable reset of search on select (which brings in some caveats): if (reason === "reset") return;
                        handleSearch(value);
                    }}
                />
            </DialogContent>
            <DialogActions>
                <NoPermissionTooltip hasPermission={isEditor}>
                    <Stack direction="row" spacing={2}>
                        <Button color="secondary" onClick={handleClosePage} disabled={!isEditor}>
                            <FormattedMessage defaultMessage="Cancel" description="Finalize onboarding" />
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleUpdateOnboardingStatus}
                            disabled={!isEditor || selectedSuppliers.length === 0}
                        >
                            <FormattedMessage defaultMessage="Onboard" description="Finalize onboarding" />
                        </Button>
                    </Stack>
                </NoPermissionTooltip>
            </DialogActions>
        </Layout>
    );
};
