import { useMutation } from "@apollo/client";
import { X } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    IconButton,
    Paper,
    Stack,
    Typography,
} from "@mui/material";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { graphql } from "@/gql";
import { useAlert } from "@/providers/Alerts";
import { useUser } from "@/providers/UserContext";

interface ApproveModalProps {
    open: boolean;
    onClose: () => void;
    supplierName: string;
    supplierId: string;
    supplierCountry?: string | null;
    supplierOrgNumber?: string | null;
}

const ApproveSupplier_OnboardingMutation = graphql(`
    mutation Onboarding_ApproveSupplier($input: ApproveSupplierInput!) {
        approveSupplier(input: $input) {
            ok
        }
    }
`);

export const ApproveModal: React.FC<ApproveModalProps> = ({
    open,
    onClose,
    supplierName,
    supplierId,
    supplierCountry,
    supplierOrgNumber,
}) => {
    const [approveSupplier] = useMutation(ApproveSupplier_OnboardingMutation);
    const [confirmed, setConfirmed] = useState(false);
    const navigate = useNavigate();
    const { alertUser } = useAlert();
    const { formatMessage } = useIntl();

    const path = location.pathname.split(/onboarding/);
    const basePath = path[0];

    const { firstName, lastName } = useUser();

    const handleApprove = () => {
        approveSupplier({
            variables: {
                input: {
                    supplierID: supplierId,
                    approved: true,
                },
            },
            onCompleted: () => {
                navigate(`${basePath}supplier-page/${supplierId}/overview`); // until we navigate to the approve dialog
            },
            onError: () => {
                alertUser({
                    title: formatMessage({
                        defaultMessage: "Failed to approve supplier",
                        description: "Failed to approve supplier alert message",
                    }),
                    value: formatMessage({
                        defaultMessage: "Please try again later",
                        description: "Please try again later alert message",
                    }),
                    severity: "error",
                });
            },
        });
        track("Onboarding Page: Approved supplier", {
            supplierId: supplierId,
        });
    };

    const handleReject = () => {
        approveSupplier({
            variables: {
                input: {
                    supplierID: supplierId,
                    approved: false,
                },
            },
            onCompleted: () => {
                onClose();
            },
            onError: () => {
                alertUser({
                    title: formatMessage({
                        defaultMessage: "Failed to reject supplier",
                        description: "Failed to reject supplier alert message",
                    }),
                    value: formatMessage({
                        defaultMessage: "Please try again later",
                        description: "Please try again later alert message",
                    }),
                    severity: "error",
                });
            },
        });
        track("Onboarding Page: Rejected supplier", {
            supplierId: supplierId,
        });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 8, right: 8 }}>
                    <X />
                </IconButton>
                <Stack spacing={1}>
                    <Typography variant="textLg">
                        <FormattedMessage
                            defaultMessage="Finalize onboarding for {supplierName}"
                            values={{ supplierName }}
                            description="Finalize onboarding header"
                        />
                    </Typography>
                    <Typography variant="textSm" color="text.text-helper">
                        <FormattedMessage
                            defaultMessage="Review and confirm the following details to continue."
                            description="Finalize onboarding header"
                        />
                    </Typography>
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ marginTop: 4 }}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Details"
                                description="Details section header, onboarding approval dialog"
                            />
                        </Typography>
                        <Paper elevation={2}>
                            <Grid container width="100%" padding={3}>
                                <Grid xs={4} pb={1}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Supplier name" description="Supplier name" />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierName}
                                    </Typography>
                                </Grid>
                                <Grid xs={4} pb={1}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage defaultMessage="Country" description="Supplier country" />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierCountry}
                                    </Typography>
                                </Grid>
                                <Grid xs={4}>
                                    <Typography variant="textSm" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="Org number"
                                            description="Supplier org number"
                                        />
                                    </Typography>
                                </Grid>
                                <Grid xs={8} textAlign="end">
                                    <Typography variant="textSm" color="text.text-helper">
                                        {supplierOrgNumber}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Stack>
                    <Stack spacing={1}>
                        <Typography variant="textMd" fontWeight={500}>
                            <FormattedMessage
                                defaultMessage="Approval process"
                                description="Final confirmation in onboarding approval dialog"
                            />
                        </Typography>
                        <FormControlLabel
                            label={
                                <Stack>
                                    <Typography variant="textSm" color="text.textSecondary" fontWeight={500}>
                                        <FormattedMessage
                                            defaultMessage="As {firstName} {lastName}, I confirm this action."
                                            values={{ firstName, lastName }}
                                            description="Confirmation checkbox"
                                        />
                                    </Typography>
                                    <Typography variant="textSm" color="text.text-helper">
                                        <FormattedMessage
                                            defaultMessage="Your digital signature will be attached to this approval decision."
                                            description="Digital signature disclaimer"
                                        />
                                    </Typography>
                                </Stack>
                            }
                            control={<Checkbox onChange={(e) => setConfirmed(e.target.checked)} />}
                        />
                    </Stack>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Stack justifyContent="space-between" alignItems="center" direction="row" width="100%">
                    <Button color="secondary" onClick={onClose}>
                        <FormattedMessage defaultMessage="Go back" description="Go back button text" />
                    </Button>

                    <Stack direction="row" spacing={2}>
                        <Button variant="outlined" color="error" onClick={() => handleReject()} disabled={!confirmed}>
                            <FormattedMessage defaultMessage="Reject" description="Reject button text" />
                        </Button>
                        <Button onClick={() => handleApprove()} disabled={!confirmed}>
                            <FormattedMessage defaultMessage="Approve" description="Approve button text" />
                        </Button>
                    </Stack>
                </Stack>
            </DialogActions>
        </Dialog>
    );
};
