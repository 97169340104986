import { PlusCircle } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import { Button, Card, CardContent, CardHeader, Divider, FormControl, Stack, Typography } from "@mui/material";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";
import { graphql } from "@/gql";
import { Checklist_TaskFragment, TaskStatus } from "@/gql/graphql";

import { TaskRow } from "./TaskRow";

graphql(`
    fragment Checklist_Task on Task {
        id
        title
        status
        supplierID
    }
`);

interface OnboardingChecklistProps {
    tasks: Checklist_TaskFragment[];
    supplierId: string;
    isEditor: boolean;
}

export const OnboardingChecklist: React.FC<OnboardingChecklistProps> = ({
    tasks: tasksInput,
    supplierId,
    isEditor,
}) => {
    const [tasks, setTasks] = useState<Checklist_TaskFragment[]>(tasksInput);

    const onAddTask = () => {
        setTasks((prev) => [
            ...prev,
            {
                __typename: "Task",
                id: "new",
                title: "",
                status: TaskStatus.Pending,
                supplierID: supplierId,
            },
        ]);
        track("Onboarding Page: Added (empty) task", {
            supplierId,
        });
    };

    return (
        <Card
            sx={{
                overflow: "auto",
                minWidth: "300px",
                height: "100%",
                minHeight: "300px",
                display: "flex",
                flexDirection: "column",
            }}
        >
            <CardHeader
                title={
                    <>
                        <Stack spacing={1}>
                            <Typography variant="textLg" fontWeight={500}>
                                <FormattedMessage
                                    defaultMessage="Onboarding checklist"
                                    description="Onboarding checklist section title"
                                />
                            </Typography>
                            <Divider />
                        </Stack>
                    </>
                }
            />
            <CardContent sx={{ height: "100%" }}>
                <Stack spacing={2} mt={-2}>
                    <NoPermissionTooltip hasPermission={isEditor}>
                        <Button
                            onClick={onAddTask}
                            color="secondary"
                            fullWidth
                            startIcon={<PlusCircle fontSize="inherit" />}
                            size="2xsmall"
                            disabled={!isEditor}
                        >
                            <FormattedMessage defaultMessage="Add new task" description="Add new task button" />
                        </Button>
                    </NoPermissionTooltip>
                    <FormControl>
                        <Stack spacing={2} maxHeight={150} overflow="auto" paddingBottom={1}>
                            {tasks.map((task, i) => (
                                <TaskRow
                                    key={task.id + i}
                                    task={task}
                                    setTask={(task: Checklist_TaskFragment) => {
                                        setTasks((prev) => {
                                            const newTasks = [...prev];
                                            newTasks[i] = task;
                                            return newTasks;
                                        });
                                    }}
                                    removeTask={() => {
                                        setTasks((prev) => prev.filter((_, index) => index !== i));
                                    }}
                                    isEditor={isEditor}
                                />
                            ))}
                        </Stack>
                    </FormControl>
                </Stack>
            </CardContent>
        </Card>
    );
};
