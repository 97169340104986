import { Plus } from "@ignite-analytics/icons";
import { track } from "@ignite-analytics/track";
import Button from "@mui/material/Button";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom-v5-compat";

import { NoPermissionTooltip } from "@/components/NoPermissionTooltip";

interface AssessSupplierButtonProps {
    supplierId: string;
    isEditor: boolean;
}

const AssessSupplierButton: React.FC<AssessSupplierButtonProps> = ({ supplierId, isEditor }) => {
    const navigate = useNavigate();

    return (
        <NoPermissionTooltip hasPermission={isEditor}>
            <Button
                startIcon={<Plus fontSize="small" />}
                onClick={() => {
                    track("Onboarding Page: Clicked add to campaign", {
                        supplierId,
                    });
                    navigate(`/assessments/campaigns/inviteToCampaign/${supplierId}`);
                }}
                disabled={!isEditor}
                color="secondary"
                size="2xsmall"
            >
                <FormattedMessage defaultMessage="Add to campaign" />
            </Button>
        </NoPermissionTooltip>
    );
};

export default AssessSupplierButton;
