export const NotesEmptyState: React.FC = () => {
    return (
        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="8" y="8" width="38" height="38" rx="19" fill="#EFF8FE" />
            <rect
                x="1.76562"
                y="1.76562"
                width="50.4688"
                height="50.4688"
                rx="25.2344"
                stroke="#38BCF7"
                strokeOpacity="0.16"
                strokeWidth="1.78125"
            />
            <rect
                x="5.32812"
                y="5.32812"
                width="43.3438"
                height="43.3438"
                rx="21.0781"
                stroke="#38BCF7"
                strokeOpacity="0.32"
                strokeWidth="1.78125"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M23.8333 18.2916C24.2706 18.2916 24.625 18.6461 24.625 19.0833H26.2083C26.2083 18.6461 26.5628 18.2916 27 18.2916C27.4372 18.2916 27.7917 18.6461 27.7917 19.0833H29.375C29.375 18.6461 29.7294 18.2916 30.1667 18.2916C30.6039 18.2916 30.9583 18.6461 30.9583 19.0833H31.75C33.0617 19.0833 34.125 20.1466 34.125 21.4583V33.3333C34.125 34.645 33.0617 35.7083 31.75 35.7083H22.25C20.9383 35.7083 19.875 34.645 19.875 33.3333V21.4583C19.875 20.1466 20.9383 19.0833 22.25 19.0833H23.0417C23.0417 18.6461 23.3961 18.2916 23.8333 18.2916ZM23.0417 24.625C23.0417 24.1877 23.3961 23.8333 23.8333 23.8333H26.2083C26.6456 23.8333 27 24.1877 27 24.625C27 25.0622 26.6456 25.4166 26.2083 25.4166H23.8333C23.3961 25.4166 23.0417 25.0622 23.0417 24.625ZM23.0417 27.7916C23.0417 27.3544 23.3961 27 23.8333 27H30.1667C30.6039 27 30.9583 27.3544 30.9583 27.7916C30.9583 28.2289 30.6039 28.5833 30.1667 28.5833H23.8333C23.3961 28.5833 23.0417 28.2289 23.0417 27.7916Z"
                fill="#069CDF"
            />
        </svg>
    );
};
